import {
    RELOAD_ORDER,
    reloadOrderFailed,
    reloadOrderSuccess,
    reloadOrderCancelled
} from "../../actions";
import {call, put, select, takeLatest, cancelled} from "redux-saga/effects";
import {fetchOrder} from "../../api/order";
import {getToken} from "../../selectors/authentication";

const handleReloadOrder = function* (action) {

    console.log(action)
    const token = yield select(getToken);

    try {
        const order = yield call(
            fetchOrder,
            {
                orderNumber: action.payload.orderNumber,
                token,
            }
        );
        yield put(
            reloadOrderSuccess(order)
        );
    } catch (error) {
        console.log(error)
        yield put(reloadOrderFailed(error));
    } finally {
        if (yield cancelled()) {
            yield put(reloadOrderCancelled())
        }
    }
};

const reloadOrderSaga = function* () {
    yield takeLatest(RELOAD_ORDER, handleReloadOrder);
};

export default reloadOrderSaga;
