import React, {lazy, useEffect, Suspense, useState} from "react";
import {Breadcrumb, Container, Dropdown, Navbar, Spinner, Row, Col} from "react-bootstrap";
import "./Page.scss";
import routes from "../../routes";
import {Link} from "react-router-dom";
import {checkAuth} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {isAuthenticated, getName} from "../../selectors/authentication";

const Page = ({route}) => {
    const dispatch = useDispatch();

    // Change the page title.
    useEffect(() => {
        document.title = `${route.title} | Your Video 2 DVD`;
        document.body.scrollTo(0, 0);

        if (route.authenticated === true) {
            dispatch(checkAuth());
        }
    }, [dispatch, route]);

    const [links, setLinks] = useState([]);

    useEffect(() => {
        let breadcrumbs = [];
        let current = route;

        while (current !== null) {
            breadcrumbs = [
                {title: current.title, path: current.path},
                ...breadcrumbs,
            ];

            current = current.hasOwnProperty("parent")
                ? routes.find((r) => r.path === route.parent)
                : null;
        }

        setLinks(breadcrumbs);
    }, [route]);

    const Component = lazy(() => import(`../../pages/${route.component}`));

    const navigation = route.navigation ?? true;
    const authenticated = useSelector(isAuthenticated)
    const name = useSelector(getName)

    return (
        <div className={`Page ${navigation ? "mt-nav" : ""} pb-5`}>
            {navigation && (
                <Navbar bg="dark" variant="dark" fixed="top">
                    <Container>
                        <Row className='w-100'>
                            <Col xs={12} className='d-flex align-items-center'>
                                <Navbar.Brand href={authenticated ? '/admin/orders' : '#'}><img
                                    src='https://cdn.shopify.com/s/files/1/0492/6731/0753/files/Logo_720x.jpg?v=1601203394'
                                    alt='Your Video 2 DVD'/></Navbar.Brand>
                                <Navbar.Toggle/>
                                <Navbar.Collapse className=" justify-content-end">
                                    {authenticated && <Navbar.Text>
                                        <Dropdown>
                                            <Dropdown.Toggle as="div">{name}</Dropdown.Toggle>
                                            <Dropdown.Menu align="right" className="Page__menu">
                                                <Dropdown.Item href={"/admin/signout"}>
                                                    Sign Out
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Navbar.Text>}
                                </Navbar.Collapse>
                            </Col>
                        </Row>
                    </Container>
                </Navbar>
            )}
            {route.breadcrumbs && (
                <Breadcrumb listProps={{className: " mb-0"}}>
                    <Container>
                        <Row className='w-100'>
                            <Col xs={12} className='d-flex align-items-center'>
                                {links.map((link, index) => (
                                    <Breadcrumb.Item
                                        key={link.path}
                                        active={index === links.length - 1}
                                        linkAs={Link}
                                        linkProps={{to: link.path}}
                                    >
                                        {link.title}
                                    </Breadcrumb.Item>
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </Breadcrumb>
            )}
            <Suspense
                fallback={
                    <div className=" Page__overlay">
                        <Spinner
                            animation=" border"
                            variant=" secondary"
                            role=" status"
                            size=" lg"
                        />
                    </div>
                }
            >
                <Component/>
            </Suspense>
        </div>
    );
};

export default Page;
