import { fork } from "redux-saga/effects";
import login from "./login";
import logout from "./logout";
import check from "./check";
import refresh from "./refresh";

const sagas = function* () {
  yield fork(login);
  yield fork(logout);
  yield fork(check);
  yield fork(refresh);
};

export default sagas;
