import {fork} from "redux-saga/effects";
import deleteUpload from "./deleteUpload";
import updateUpload from "./updateUpload";

const sagas = function* () {
    yield fork(updateUpload);
    yield fork(deleteUpload);
};

export default sagas;
