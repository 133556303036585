import { Router, Route, Switch } from "react-router-dom";
import routes from "./routes";
import { Page } from "./components";
import { history } from "./utilities/router";

import axiosRetry from 'axios-retry';
import axios from 'axios';
import { useEffect } from 'react';

axiosRetry(axios, { retries: 3 });

function App() {
    useEffect(() => {
        axios.interceptors.response.use((response) => {
            const code = response?.data?.error?.code;
            if((response.status === 403 || response.status === 401) && code !== "ORDER_NOT_FULFILLED") {
                window.location = '/admin/signin';
            }

            return response;
        });
    }, []);

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} exact={true}>
              <Page route={route} />
            </Route>
          ))}
        </Switch>
      </Router>
    </div>
  );
}

export default App;