import {
    fetchCustomerOrder,
    SET_CUSTOMER_PARAMETERS
} from "../../actions";
import {call, put, takeLatest} from "redux-saga/effects";
import Cookies from 'js-cookie';

const handleSetParameters = function* (action) {
    const {accessKey, orderNumber, store} = action.payload;

    yield put(fetchCustomerOrder());

    if (store) {
        yield call(Cookies.set, 'PARAMETERS', JSON.stringify({orderNumber, accessKey}));
    }
};

const storeParametersSaga = function* () {
    yield takeLatest(SET_CUSTOMER_PARAMETERS, handleSetParameters);
};

export default storeParametersSaga;
