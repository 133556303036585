import axios from "axios";
import {InvalidTokenError} from "../errors/authentication";
import {OrderExpiredError, OrderNotFulfilledError} from "../errors/orders";
import {acceptStatuses} from "../utilities/axios";
import ApiError from "../errors/ApiError";

export const fetchOrder = ({orderNumber, token}) =>
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/orders/show/${orderNumber}`, {
            headers: {Authorization: `Bearer ${token}`},
            validateStatus: acceptStatuses(200, 401, 403),
        })
        .then(({data, status}) => {
            // If we were successful, return the token.
            if (status === 200) {
                return data.order
            }

            // If credentials are invalid as indicated by the API, throw appropriate error.
            if (data.error.code === "TOKEN_INVALID") {
                throw new InvalidTokenError();
            }

            if (data.error.code === "ORDER_NOT_FULFILLED") {
                throw new OrderNotFulfilledError();
            }

            if (data.error.code === "ORDER_EXPIRED") {
                throw new OrderExpiredError();
            }

            throw new ApiError();
        });

export const updateOrder = ({uuid, token, expiresAt, status}) =>
    axios
        .patch(`${process.env.REACT_APP_API_URL}/api/orders/${uuid}`,
            {
                expires_at: expiresAt,
                status
            },
            {
                headers: {Authorization: `Bearer ${token}`},
                validateStatus: acceptStatuses(200, 401),
            }
        )
        .then(({data, status}) => {
            // If we were successful, return the token.
            if (status === 200) {
                return data.success
            }

            // If credentials are invalid as indicated by the API, throw appropriate error.
            if (data.error.code === "TOKEN_INVALID") {
                throw new InvalidTokenError();
            }

            throw new ApiError();
        });
