import { getToken } from "./getToken";
import decode from "jwt-decode";

export const getName = (state) => {
  const token = getToken(state);

  try {
    const decoded = decode(token);
    return token ? `${decoded.user.first_name} ${decoded.user.last_name}` : null;
  } catch (error) {
    return null;
  }
};
