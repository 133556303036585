import {
    FETCH_CUSTOMER_ORDER,
    FETCH_CUSTOMER_ORDER_CANCELLED,
    FETCH_CUSTOMER_ORDER_FAILED,
    FETCH_CUSTOMER_ORDER_SUCCESS,
    SET_CUSTOMER_PARAMETERS
} from "../actions";

const initialState = {
    order: null,
    loading: 0,
    error: null,
    ready: false,
    orderNumber: null,
    accessKey: null,
};

const reducer = (state = initialState, action) => {
        switch (action.type) {
            case SET_CUSTOMER_PARAMETERS:
                return {
                    ...state,
                    orderNumber: action.payload.orderNumber,
                    accessKey: action.payload.accessKey
                }
            case FETCH_CUSTOMER_ORDER:
                return {
                    ...state,
                    loading: state.loading + 1,
                    ready: false,
                    error: null,
                };
            case FETCH_CUSTOMER_ORDER_SUCCESS:
                return {
                    ...state,
                    order: action.payload,
                    ready: true,
                    loading: Math.max(0, state.loading - 1),
                };
            case FETCH_CUSTOMER_ORDER_FAILED:
                return {
                    ...state,
                    loading: Math.max(0, state.loading - 1),
                    ready: true,
                    error: action.payload,
                };
            case FETCH_CUSTOMER_ORDER_CANCELLED:
                return {
                    ...state,
                    ready: false,
                    loading: Math.max(0, state.loading - 1)
                }
            default:
                return state;
        }
    }
;

export default reducer;
