import {
    FETCH_ORDERS,
    fetchOrdersFailed,
    fetchOrdersSuccess,
    fetchOrdersCancelled
} from "../../actions";
import {call, put, select, takeLatest, cancelled} from "redux-saga/effects";
import {fetchOrders} from "../../api/orders";
import {getToken} from "../../selectors/authentication";

const handleFetchOrders = function* (action) {
    const token = yield select(getToken);

    try {
        const {orders, perPage, total, hasMorePages, page} = yield call(
            fetchOrders,
            {
                page: action.payload.page,
                term: action.payload.term,
                filter: action.payload.filter,
                token,
            }
        );
        yield put(
            fetchOrdersSuccess({
                orders,
                perPage,
                total,
                hasMorePages,
                page,
            })
        );
    } catch (error) {
        yield put(fetchOrdersFailed(error));
    } finally {
        if (yield cancelled()) {
            yield put(fetchOrdersCancelled())
        }
    }
};

const fetchOrdersSaga = function* () {
    yield takeLatest(FETCH_ORDERS, handleFetchOrders);
};

export default fetchOrdersSaga;
