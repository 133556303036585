import {fork} from "redux-saga/effects";
import authentication from "./authentication";
import customer from "./customer";
import orders from "./orders";
import order from "./order";
import uploads from "./uploads";

const sagas = function* () {
    yield fork(customer);
    yield fork(authentication);
    yield fork(orders);
    yield fork(order);
    yield fork(uploads);
};

export default sagas;
