import ApiError from "../ApiError";
import messages from "../../constants/messages";

class InvalidCredentialsError extends ApiError {
  constructor() {
    super(messages.AUTH_CREDENTIALS_INVALID);
  }
}

export default InvalidCredentialsError;
