import ApiError from "../ApiError";
import messages from "../../constants/messages";

class InvalidTokenError extends ApiError {
  constructor() {
    super(messages.AUTH_TOKEN_INVALID);
  }
}

export default InvalidTokenError;
