import axios from "axios";
import {acceptStatuses} from "../utilities/axios";
import {InvalidTokenError} from "../errors/authentication";
import ApiError from "../errors/ApiError";
import {OrderExpiredError, OrderNotFulfilledError} from "../errors/orders";

export const fetchCustomerOrder = ({orderNumber, accessKey}) =>
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/orders/show/${orderNumber}`, {
            params: {accessKey},
            validateStatus: acceptStatuses(200, 401, 403),
        })
        .then(({data, status}) => {
            // If we were successful, return the token.
            if (status === 200) {
                return data.order
            }

            // If credentials are invalid as indicated by the API, throw appropriate error.
            if (data.error.code === "TOKEN_INVALID") {
                throw new InvalidTokenError();
            }

            if(data.error.code === "ORDER_NOT_FULFILLED") {
                throw new OrderNotFulfilledError();
            }

            if (data.error.code === "ORDER_EXPIRED") {
                throw new OrderExpiredError();
            }

            throw new ApiError();
        });