import {
  CHECK_AUTH,
  checkAuth,
  checkAuthFailed,
  checkAuthSuccess,
  refreshToken,
  setToken,
} from "../../actions";
import { takeLatest, select, put, all, call, delay } from "redux-saga/effects";
import { history } from "../../utilities/router";
import { getToken, isAuthenticated } from "../../selectors/authentication";
import Cookies from "js-cookie";
import { getTokenExpiryTime } from "../../selectors/authentication/getTokenExpiryTime";

const handleCheck = function* () {
  try {
    let token = yield select(getToken);

    // Check for a token cookie if we don't have one.
    if (!token) {
      token = Cookies.get(process.env.REACT_APP_TOKEN_COOKIE);
      yield put(setToken(token));
    }

    const authenticated = yield select(isAuthenticated);

    // If we don't have a token, or it's expired, go to sign in.
    if (!authenticated) {
      yield put(checkAuthFailed());
      Cookies.remove(process.env.REACT_APP_TOKEN_COOKIE);
      history.push("/admin/signin");
      return;
    }

    // If we have a token and it hasn't expired, it must be valid.
    yield put(checkAuthSuccess());

    // Check if we need to refresh and if so, refresh.
    const expiry = yield select(getTokenExpiryTime);
    const secondsRemaining = Math.floor((expiry - Date.now()) / 1000);

    if (secondsRemaining < process.env.REACT_APP_REFRESH_SECONDS) {
      yield put(refreshToken());
    }
  } catch (error) {
    yield put(checkAuthFailed(error));
    Cookies.remove(process.env.REACT_APP_TOKEN_COOKIE);
    history.push("/admin/signin");
  }
};

const callPeriodically = function* () {
  yield delay(30 * 1000);
  yield put(checkAuth());
  yield call(callPeriodically);
};

const checkSaga = function* () {
  yield all([takeLatest([CHECK_AUTH], handleCheck), call(callPeriodically)]);
};

export default checkSaga;