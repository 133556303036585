import ApiError from "../ApiError";
import messages from "../../constants/messages";

class OrderExpiredError extends ApiError {
    constructor() {
        super(messages.ORDER_EXPIRED);
    }
}

export default OrderExpiredError;
