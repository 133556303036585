import {
  UPDATE_UPLOAD,
  updateUploadFailed,
  updateUploadSuccess,
  updateUploadCancelled,
} from "../../actions";
import { call, put, select, takeLatest, cancelled } from "redux-saga/effects";
import { updateUpload } from "../../api/upload";
import { getToken } from "../../selectors/authentication";

const handleUpdateUpload = function* (action) {
  const token = yield select(getToken);

  try {
    yield call(updateUpload, {
      uuid: action.payload.uuid,
      reference: action.payload.reference,
      filename: action.payload.filename,
      token,
    });
    yield put(
      updateUploadSuccess({
        uuid: action.payload.uuid,
        reference: action.payload.reference,
        filename: action.payload.filename,
      })
    );
  } catch (error) {
    yield put(updateUploadFailed(error));
  } finally {
    if (yield cancelled()) {
      yield put(updateUploadCancelled());
    }
  }
};

const updateUploadSaga = function* () {
  yield takeLatest(UPDATE_UPLOAD, handleUpdateUpload);
};

export default updateUploadSaga;