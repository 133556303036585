import axios from "axios";
import {
  InvalidCredentialsError,
  InvalidTokenError,
} from "../errors/authentication";
import { acceptStatuses } from "../utilities/axios";
import ApiError from "../errors/ApiError";

export const login = (email, password) =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/auth/login`,
      { email, password },
      { validateStatus: acceptStatuses(200, 401) }
    )
    .then(({ data, status }) => {
      // If we were successful, return the token.
      if (status === 200) {
        return data.token;
      }

      // If credentials are invalid as indicated by the API, throw appropriate error.
      if (data.error.code === "CREDENTIALS_INVALID") {
        throw new InvalidCredentialsError();
      }

      throw new ApiError();
    });

export const refreshToken = (token) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/auth/refresh`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: acceptStatuses(200, 401),
    })
    .then(({ data, status }) => {
      // If we were successful, return the token.
      if (status === 200) {
        return data.token;
      }

      // If credentials are invalid as indicated by the API, throw appropriate error.
      if (data.error.code === "TOKEN_INVALID") {
        throw new InvalidTokenError();
      }

      throw new ApiError();
    });
