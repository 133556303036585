import {
    FETCH_ORDER,
    fetchOrderFailed,
    fetchOrderSuccess,
    fetchOrderCancelled
} from "../../actions";
import {call, put, select, takeLatest, cancelled} from "redux-saga/effects";
import {fetchOrder} from "../../api/order";
import {getToken} from "../../selectors/authentication";

const handleFetchOrder = function* (action) {
    const token = yield select(getToken);

    try {
        const order = yield call(
            fetchOrder,
            {
                orderNumber: action.payload.orderNumber,
                token,
            }
        );
        yield put(
            fetchOrderSuccess(order)
        );
    } catch (error) {
        console.log(error)
        yield put(fetchOrderFailed(error));
    } finally {
        if (yield cancelled()) {
            yield put(fetchOrderCancelled())
        }
    }
};

const fetchOrderSaga = function* () {
    yield takeLatest(FETCH_ORDER, handleFetchOrder);
};

export default fetchOrderSaga;
