import { createAction } from "../utilities/redux";

export const LOGIN = "auth/login";
export const LOGIN_SUCCESS = "auth/login/success";
export const LOGIN_FAILED = "auth/login/failed";
export const LOGIN_CANCELLED = "auth/login/cancelled";

export const CHECK_AUTH = "auth/check";
export const CHECK_AUTH_SUCCESS = "auth/check/success";
export const CHECK_AUTH_FAILED = "auth/check/failed";
export const SET_TOKEN = "auth/token/set";

export const LOGOUT = "auth/logout";

export const REFRESH_TOKEN = "auth/refresh";
export const REFRESH_TOKEN_SUCCESS = "auth/refresh/success";
export const REFRESH_TOKEN_FAILED = "auth/refresh/failed";

export const login = (email, password) =>
  createAction(LOGIN, { email, password });

export const loginSuccess = (token) => createAction(LOGIN_SUCCESS, { token });

export const loginFailed = (error) => createAction(LOGIN_FAILED, { error });

export const loginCancelled = () => createAction(LOGIN_CANCELLED);

export const checkAuth = () => createAction(CHECK_AUTH);

export const checkAuthSuccess = () => createAction(CHECK_AUTH_SUCCESS);

export const checkAuthFailed = (error) =>
  createAction(CHECK_AUTH_FAILED, { error });

export const setToken = (token) => createAction(SET_TOKEN, { token });

export const logout = () => createAction(LOGOUT);

export const refreshToken = () => createAction(REFRESH_TOKEN);

export const refreshTokenFailed = (error) =>
  createAction(REFRESH_TOKEN_FAILED, { error });

export const refreshTokenSuccess = (token) =>
  createAction(REFRESH_TOKEN_SUCCESS, { token });
