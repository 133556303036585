import {
    FETCH_CUSTOMER_ORDER,
    fetchCustomerOrderFailed,
    fetchCustomerOrderSuccess,
    fetchCustomerOrderCancelled
} from "../../actions";
import {call, put, select, takeLatest, cancelled} from "redux-saga/effects";
import {fetchCustomerOrder} from "../../api/customer";
import {getParameters} from "../../selectors/customer";
import {OrderNotFulfilledError, OrderExpiredError} from "../../errors/orders";
import {history} from "../../utilities/router";

const handleFetchCustomerOrder = function* () {
    const {orderNumber, accessKey} = yield select(getParameters);

    try {
        const order = yield call(
            fetchCustomerOrder,
            {
                orderNumber,
                accessKey,
            }
        );
        yield put(
            fetchCustomerOrderSuccess(order)
        );
    } catch (error) {
        if (error instanceof OrderNotFulfilledError) {
            history.push('/customer/order/not-fulfilled');
        }
        if (error instanceof OrderExpiredError) {
            history.push('/customer/order/expired');
        }
        yield put(fetchCustomerOrderFailed(error));
    } finally {
        if (yield cancelled()) {
            yield put(fetchCustomerOrderCancelled())
        }
    }
};

const fetchCustomerOrderSaga = function* () {
    yield takeLatest(FETCH_CUSTOMER_ORDER, handleFetchCustomerOrder);
};

export default fetchCustomerOrderSaga;
