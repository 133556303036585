import {
  REFRESH_TOKEN,
  refreshTokenFailed,
  refreshTokenSuccess,
} from "../../actions";
import { select, takeLatest, call, put } from "redux-saga/effects";
import Cookies from "js-cookie";
import { getToken } from "../../selectors/authentication";
import { refreshToken } from "../../api/authentication";
import { history } from "../../utilities/router";

const handleRefresh = function* () {
  try {
    let token = yield select(getToken);
    token = yield call(refreshToken, token);
    yield put(refreshTokenSuccess(token));
    Cookies.set(process.env.REACT_APP_TOKEN_COOKIE, token);
  } catch (error) {
    yield put(refreshTokenFailed(error));
    Cookies.remove(process.env.REACT_APP_TOKEN_COOKIE);
    history.push("/admin/signin");
  }
};

const refreshSaga = function* () {
  yield takeLatest(REFRESH_TOKEN, handleRefresh);
};

export default refreshSaga;