const routes = [
  // Admin routes
  {
    title: "Sign In",
    path: "/admin/signin",
    component: "admin/SignIn",
    navigation: true,
  },
  {
    title: "Sign Out",
    path: "/admin/signout",
    component: "admin/SignOut",
    navigation: false,
  },
  {
    title: "Orders",
    path: "/admin/orders",
    component: "admin/Orders",
    breadcrumbs: true,
    authenticated: true,
  },
  {
    title: "Order",
    path: "/admin/orders/:orderNumber",
    component: "admin/Order",
    parent: "/admin/orders",
    breadcrumbs: true,
    authenticated: true,
  },

  // Customer routes
  {
    title: "Order",
    path: "/customer/order",
    component: "customer/Order",
    breadcrumbs: false,
    navigation: true,
  },
  {
    title: "Not Fulfilled",
    path: "/customer/order/not-fulfilled",
    component: "customer/NotFulfilled",
    breadcrumbs: false,
    navigation: true,
  },
  {
    title: "Expired",
    path: "/customer/order/expired",
    component: "customer/Expired",
    breadcrumbs: false,
    navigation: true,
  },


  // Common routes
  {
    title: "Landing",
    path: "/",
    component: "all/Home",
    navigation: true,
  },
  {
    title: "Not Found",
    path: "*",
    component: "all/NotFound",
    navigation: true,
  },
];

export default routes;
