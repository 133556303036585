export const FETCH_ORDERS = "orders/fetch";
export const FETCH_ORDERS_SUCCESS = "orders/fetch/success";
export const FETCH_ORDERS_FAILED = "orders/fetch/failed";
export const FETCH_ORDERS_CANCELLED = "orders/fetch/cancelled";

export const fetchOrders = ({page, term, filter}) => ({
    type: FETCH_ORDERS,
    payload: {page, term, filter},
});

export const fetchOrdersSuccess = (orders) => ({
    type: FETCH_ORDERS_SUCCESS,
    payload: orders,
});

export const fetchOrdersFailed = (error) => ({
    type: FETCH_ORDERS_FAILED,
    payload: error,
});

export const fetchOrdersCancelled = () => ({
    type: FETCH_ORDERS_CANCELLED
});
