import {
  DELETE_UPLOAD,
  deleteUploadFailed,
  deleteUploadSuccess,
  deleteUploadCancelled,
} from "../../actions";
import { call, put, select, takeLatest, cancelled } from "redux-saga/effects";
import { deleteUpload } from "../../api/upload";
import { getToken } from "../../selectors/authentication";

const handleDeleteUpload = function* (action) {
  const token = yield select(getToken);

  try {
    yield call(deleteUpload, {
      uuid: action.payload.uuid,
      token,
    });
    yield put(deleteUploadSuccess({ uuid: action.payload.uuid }));
  } catch (error) {
    yield put(deleteUploadFailed(error));
  } finally {
    if (yield cancelled()) {
      yield put(deleteUploadCancelled());
    }
  }
};

const deleteUploadSaga = function* () {
  yield takeLatest(DELETE_UPLOAD, handleDeleteUpload);
};

export default deleteUploadSaga;