import axios from "axios";
import {InvalidTokenError} from "../errors/authentication";
import {acceptStatuses} from "../utilities/axios";
import ApiError from "../errors/ApiError";

export const updateUpload = ({uuid, token, reference, filename}) =>
    axios
        .patch(`${process.env.REACT_APP_API_URL}/api/uploads/${uuid}`,
            {
                reference,
                filename
            },
            {
                headers: {Authorization: `Bearer ${token}`},
                validateStatus: acceptStatuses(200, 401),
            }
        )
        .then(({data, status}) => {
            // If we were successful, return the token.
            if (status === 200) {
                return data.success
            }

            // If credentials are invalid as indicated by the API, throw appropriate error.
            if (data.error.code === "TOKEN_INVALID") {
                throw new InvalidTokenError();
            }

            throw new ApiError();
        });

export const deleteUpload = ({uuid, token}) =>
    axios
        .delete(`${process.env.REACT_APP_API_URL}/api/uploads/${uuid}`,
            {
                headers: {Authorization: `Bearer ${token}`},
                validateStatus: acceptStatuses(200, 401),
            }
        )
        .then(({data, status}) => {
            // If we were successful, return the token.
            if (status === 200) {
                return data.success
            }

            // If credentials are invalid as indicated by the API, throw appropriate error.
            if (data.error.code === "TOKEN_INVALID") {
                throw new InvalidTokenError();
            }

            throw new ApiError();
        });
