import {
    DELETE_UPLOAD_SUCCESS,
    FETCH_ORDER,
    FETCH_ORDER_CANCELLED,
    FETCH_ORDER_FAILED,
    FETCH_ORDER_SUCCESS,
    UPDATE_UPLOAD_CANCELLED,
    UPDATE_UPLOAD_FAILED,
    UPDATE_UPLOAD_SUCCESS,
    RELOAD_ORDER,
    RELOAD_ORDER_SUCCESS,
    RELOAD_ORDER_FAILED, UPDATE_UPLOAD_STATUS
} from "../actions";

const initialState = {
    order: null,
    loading: 0,
    error: null,
    ready: false,
};

const reducer = (state = initialState, action) => {
        switch (action.type) {
            case FETCH_ORDER:
                return {
                    ...state,
                    loading: state.loading + 1,
                    ready: false,
                    error: null,
                };
            case FETCH_ORDER_SUCCESS:
                return {
                    ...state,
                    order: action.payload,
                    ready: true,
                    loading: Math.max(0, state.loading - 1),
                };
            case RELOAD_ORDER:
                return {
                    ...state,
                    error: null,
                };
            case RELOAD_ORDER_SUCCESS:
                return {
                    ...state,
                    order: action.payload,
                };
            case UPDATE_UPLOAD_STATUS:
                return {
                    ...state,
                    order: {
                        ...state.order,
                        line_items: state.order.line_items.map(item => ({
                            ...item,
                            uploads: item.uploads.map(upload => upload.uuid === action.payload.upload.uuid ? action.payload.upload : upload)
                        }))
                    }
                }
            case UPDATE_UPLOAD_SUCCESS:
                return {
                    ...state,
                    order: {
                        ...state.order,
                        line_items: state.order.line_items.map(item => ({
                            ...item,
                            uploads: item.uploads.map(upload => upload.uuid === action.payload.uuid ? {
                                ...upload,
                                reference: action.payload.reference,
                                filename: action.payload.filename,
                            } : upload)
                        }))
                    }
                }
            case FETCH_ORDER_FAILED:
                return {
                    ...state,
                    loading: Math.max(0, state.loading - 1),
                    ready: true,
                    error: action.payload,
                };
            case RELOAD_ORDER_FAILED:
                return {
                    ...state,
                    error: action.payload,
                };
            case
            UPDATE_UPLOAD_FAILED:
            case
            UPDATE_UPLOAD_CANCELLED:
                return {
                    ...state,
                    loading: Math.max(0, state.loading - 1),
                    error: action.payload
                }
            case
            FETCH_ORDER_CANCELLED:
                return {
                    ...state,
                    ready: false,
                    loading: Math.max(0, state.loading - 1)
                }
            case
            DELETE_UPLOAD_SUCCESS:
                return {
                    ...state,
                    order: {
                        ...state.order,
                        line_items: state.order.line_items.map(item => ({
                            ...item,
                            uploads: item.uploads.filter(upload => upload.uuid !== action.payload.uuid)
                        }))
                    }
                }
            default:
                return state;
        }
    }
;

export default reducer;
