import {fork} from "redux-saga/effects";
import fetchOrder from "./fetchOrder";
import updateOrder from "./updateOrder";
import reloadOrder from "./reloadOrder";

const sagas = function* () {
    yield fork(fetchOrder);
    yield fork(updateOrder);
    yield fork(reloadOrder);
};

export default sagas;
