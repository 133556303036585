import { LOGOUT } from "../../actions";
import { takeLatest, call } from "redux-saga/effects";
import Cookies from "js-cookie";
import { history } from "../../utilities/router";

const handleLogout = function* () {
  // Unset token cookie.
  yield call(Cookies.remove, process.env.REACT_APP_TOKEN_COOKIE);

  // Redirect to sign in.
  history.push("/admin/signin");
};

const logoutSaga = function* () {
  yield takeLatest(LOGOUT, handleLogout);
};

export default logoutSaga;
