import axios from "axios";
import { InvalidTokenError } from "../errors/authentication";
import { acceptStatuses } from "../utilities/axios";
import ApiError from "../errors/ApiError";

export const fetchOrders = ({ page = 1, term = "", filter = "all", token }) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/orders/search`, {
      params: { page, term, filter },
      headers: { Authorization: `Bearer ${token}` },
      validateStatus: acceptStatuses(200, 401),
    })
    .then(({ data, status }) => {
      // If we were successful, return the token.
      if (status === 200) {
        return {
          orders: data.orders,
          perPage: data.per_page,
          hasMorePages: data.more_pages,
          total: data.total,
          page: data.page,
        };
      }

      // If credentials are invalid as indicated by the API, throw appropriate error.
      if (data.error.code === "TOKEN_INVALID") {
        throw new InvalidTokenError();
      }

      throw new ApiError();
    });
