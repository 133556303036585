import {
  UPDATE_ORDER,
  updateOrderFailed,
  updateOrderSuccess,
  updateOrderCancelled,
  fetchOrder,
} from "../../actions";
import { call, put, select, takeLatest, cancelled } from "redux-saga/effects";
import { updateOrder } from "../../api/order";
import { getToken } from "../../selectors/authentication";
import { getOrder } from "../../selectors/order";

const handleUpdateOrder = function* (action) {
  const token = yield select(getToken);
  const order = yield select(getOrder);

  try {
    yield call(updateOrder, {
      uuid: action.payload.uuid,
      expiresAt: action.payload.expiresAt,
      status: action.payload.status,
      token,
    });
    yield put(updateOrderSuccess());
    yield put(fetchOrder({ orderNumber: order.order_number }));
  } catch (error) {
    yield put(updateOrderFailed(error));
  } finally {
    if (yield cancelled()) {
      yield put(updateOrderCancelled());
    }
  }
};

const updateOrderSaga = function* () {
  yield takeLatest(UPDATE_ORDER, handleUpdateOrder);
};

export default updateOrderSaga;