import {fork} from "redux-saga/effects";
import checkParameters from "./checkParameters";
import fetchCustomerOrder from "./fetchCustomerOrder";
import storeParameters from "./storeParameters";

const sagas = function* () {
    yield fork(checkParameters);
    yield fork(fetchCustomerOrder);
    yield fork(storeParameters);
};

export default sagas;
