import { getToken } from "./getToken";
import decode from "jwt-decode";

export const getTokenExpiryTime = (state) => {
  const token = getToken(state);

  try {
    return token ? decode(token).exp * 1000 : null;
  } catch (error) {
    return null;
  }
};
