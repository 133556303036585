import "./LoadingSpinner.scss";
import loader from "../../assets/loader.svg";
import PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";

const LoadingSpinner = ({visible, children}) => {
    return (
        <CSSTransition
            in={visible}
            timeout={150}
            classNames="spinner"
            unmountOnExit
        >
            <div className="spinner">
                <img className="spinner__image" src={loader} alt="Loading..."/>
                <div className="spinner__message">{children || "Loading..."}</div>
            </div>
        </CSSTransition>
    );
};

LoadingSpinner.propTypes = {
    visible: PropTypes.bool,
    children: PropTypes.any,
};

LoadingSpinner.defaultProps = {
    visible: false,
};

export default LoadingSpinner;