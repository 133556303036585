export const FETCH_ORDER = "order/fetch";
export const FETCH_ORDER_SUCCESS = "order/fetch/success";
export const FETCH_ORDER_FAILED = "order/fetch/failed";
export const FETCH_ORDER_CANCELLED = "order/fetch/cancelled";

export const fetchOrder = ({orderNumber}) => ({
    type: FETCH_ORDER,
    payload: {orderNumber},
});

export const fetchOrderSuccess = (order) => ({
    type: FETCH_ORDER_SUCCESS,
    payload: order,
});

export const fetchOrderFailed = (error) => ({
    type: FETCH_ORDER_FAILED,
    payload: error,
});

export const fetchOrderCancelled = () => ({
    type: FETCH_ORDER_CANCELLED
});

export const UPDATE_ORDER = "order/update";
export const UPDATE_ORDER_SUCCESS = "order/update/success";
export const UPDATE_ORDER_FAILED = "order/update/failed";
export const UPDATE_ORDER_CANCELLED = "order/update/cancelled";

export const updateOrder = ({uuid, expiresAt, status}) => ({
    type: UPDATE_ORDER,
    payload: {uuid, expiresAt, status},
});

export const updateOrderSuccess = () => ({
    type: UPDATE_ORDER_SUCCESS,
});

export const updateOrderFailed = (error) => ({
    type: UPDATE_ORDER_FAILED,
    payload: error,
});

export const updateOrderCancelled = () => ({
    type: UPDATE_ORDER_CANCELLED
});

export const RELOAD_ORDER = "order/reload";
export const RELOAD_ORDER_SUCCESS = "order/reload/success";
export const RELOAD_ORDER_FAILED = "order/reload/failed";
export const RELOAD_ORDER_CANCELLED = "order/reload/cancelled";

export const reloadOrder = ({orderNumber}) => ({
    type: RELOAD_ORDER,
    payload: {orderNumber},
});

export const reloadOrderSuccess = (order) => ({
    type: RELOAD_ORDER_SUCCESS,
    payload: order,
});

export const reloadOrderFailed = (error) => ({
    type: RELOAD_ORDER_FAILED,
    payload: error,
});

export const reloadOrderCancelled = () => ({
    type: RELOAD_ORDER_CANCELLED
});
