import {
  LOGIN,
  loginFailed,
  loginCancelled,
  loginSuccess,
} from "../../actions";
import { takeLatest, put, cancelled, call } from "redux-saga/effects";
import { login } from "../../api/authentication";
import { history } from "../../utilities/router";
import Cookies from "js-cookie";

const handleLogin = function* (action) {
  try {
    const { email, password } = action.payload;
    const token = yield call(login, email, password);

    // Store the token locally and inside a cookie.
    yield put(loginSuccess(token));
    Cookies.set(process.env.REACT_APP_TOKEN_COOKIE, token);

    history.push("/admin/orders");
  } catch (error) {
    yield put(loginFailed(error));
  } finally {
    if (yield cancelled()) {
      yield put(loginCancelled());
    }
  }
};

const loginSaga = function* () {
  yield takeLatest(LOGIN, handleLogin);
};

export default loginSaga;
