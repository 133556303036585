import {
    FETCH_ORDERS, FETCH_ORDERS_CANCELLED,
    FETCH_ORDERS_FAILED,
    FETCH_ORDERS_SUCCESS,
} from "../actions";

const initialState = {
    orders: [],
    ready: false,

    pagination: {
        page: null,
        hasMorePages: null,
        perPage: null,
        total: null,
    },

    loading: 0,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDERS:
            return {
                ...state,
                loading: state.loading + 1,
                error: null,
            };
        case FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload.orders,
                ready: true,
                pagination: {
                    page: action.payload.page,
                    hasMorePages: action.payload.hasMorePages,
                    perPage: action.payload.perPage,
                    total: action.payload.total,
                },
                loading: Math.max(0, state.loading - 1),
            };
        case FETCH_ORDERS_FAILED:
            return {
                ...state,
                ready: true,
                loading: Math.max(0, state.loading - 1),
                error: action.payload,
            };
        case FETCH_ORDERS_CANCELLED:
            return {
                ...state,
                loading: Math.max(0, state.loading - 1)
            }
        default:
            return state;
    }
};

export default reducer;
