export const UPDATE_UPLOAD = "upload/update";
export const UPDATE_UPLOAD_SUCCESS = "upload/update/success";
export const UPDATE_UPLOAD_FAILED = "upload/update/failed";
export const UPDATE_UPLOAD_CANCELLED = "upload/update/cancelled";

export const UPDATE_UPLOAD_STATUS = "upload/status/update";

export const updateUploadStatus = (upload) => ({
    type: UPDATE_UPLOAD_STATUS,
    payload: {upload}
});

export const updateUpload = ({uuid, reference, filename}) => ({
    type: UPDATE_UPLOAD,
    payload: {uuid, reference, filename},
});

export const updateUploadSuccess = ({uuid, reference, filename}) => ({
    type: UPDATE_UPLOAD_SUCCESS,
    payload: {uuid, reference, filename}
});

export const updateUploadFailed = (error) => ({
    type: UPDATE_UPLOAD_FAILED,
    payload: error,
});

export const updateUploadCancelled = () => ({
    type: UPDATE_UPLOAD_CANCELLED
});

export const DELETE_UPLOAD = "upload/delete";
export const DELETE_UPLOAD_SUCCESS = "upload/delete/success";
export const DELETE_UPLOAD_FAILED = "upload/delete/failed";
export const DELETE_UPLOAD_CANCELLED = "upload/delete/cancelled";

export const deleteUpload = ({uuid}) => ({
    type: DELETE_UPLOAD,
    payload: {uuid},
});

export const deleteUploadSuccess = ({uuid}) => ({
    type: DELETE_UPLOAD_SUCCESS,
    payload: {uuid}
});

export const deleteUploadFailed = (error) => ({
    type: DELETE_UPLOAD_FAILED,
    payload: error,
});

export const deleteUploadCancelled = () => ({
    type: DELETE_UPLOAD_CANCELLED
});

export const UPLOAD_PART = 'part/upload'

export const uploadPart = () => ({
    type: UPLOAD_PART
})