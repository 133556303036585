import {
    CHECK_CUSTOMER_PARAMETERS, setCustomerParameters,
} from "../../actions";
import {call, put, takeLatest} from "redux-saga/effects";
import Cookies from 'js-cookie';

const handleCheckParameters = function* () {
    const parameters = yield call(Cookies.get, 'PARAMETERS');

    if (parameters) {
        const {orderNumber, accessKey} = JSON.parse(parameters);
        yield put(setCustomerParameters({orderNumber, accessKey, store: false}))
    }
};

const checkParametersSaga = function* () {
    yield takeLatest(CHECK_CUSTOMER_PARAMETERS, handleCheckParameters);
};

export default checkParametersSaga;
