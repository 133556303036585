import * as reducers from "./reducers";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";

const configureStore = (preloadedState) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewareEnhancer = applyMiddleware(sagaMiddleware);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools({ trace: true })(...enhancers);

  const rootReducer = combineReducers(reducers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  sagaMiddleware.run(sagas);

  return store;
};

export default configureStore;
