export const SET_CUSTOMER_PARAMETERS = "customer/parameters/set";

export const setCustomerParameters = ({orderNumber, accessKey, store = true}) => ({
    type: SET_CUSTOMER_PARAMETERS,
    payload: {orderNumber, accessKey, store}
})

export const CHECK_CUSTOMER_PARAMETERS = 'customer/parameters/check';

export const checkCustomerParameters = () => ({
    type: CHECK_CUSTOMER_PARAMETERS
})

export const FETCH_CUSTOMER_ORDER = "customer/order/fetch";
export const FETCH_CUSTOMER_ORDER_SUCCESS = "customer/order/fetch/success";
export const FETCH_CUSTOMER_ORDER_FAILED = "customer/order/fetch/failed";
export const FETCH_CUSTOMER_ORDER_CANCELLED = "customer/order/fetch/cancelled";

export const fetchCustomerOrder = () => ({
    type: FETCH_CUSTOMER_ORDER,
});

export const fetchCustomerOrderSuccess = (order) => ({
    type: FETCH_CUSTOMER_ORDER_SUCCESS,
    payload: order,
});

export const fetchCustomerOrderFailed = (error) => ({
    type: FETCH_CUSTOMER_ORDER_FAILED,
    payload: error,
});

export const fetchCustomerOrderCancelled = () => ({
    type: FETCH_CUSTOMER_ORDER_CANCELLED
});