import {
  LOGIN,
  LOGIN_CANCELLED,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_SUCCESS,
  SET_TOKEN,
} from "../actions";

const initialState = {
  token: null,
  ready: false,
  loading: 0,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_TOKEN:
    case LOGIN:
      return {
        ...state,
        loading: state.loading + 1,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        loading: Math.max(0, state.loading - 1),
      };
    case REFRESH_TOKEN_FAILED:
    case LOGIN_FAILED:
      return {
        ...state,
        loading: Math.max(0, state.loading - 1),
        error: action.payload.error,
        token: null,
      };
    case LOGIN_CANCELLED:
      return {
        ...state,
        loading: Math.max(0, state.loading - 1),
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        loading: Math.max(0, state.loading - 1),
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default reducer;
